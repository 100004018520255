// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer */
footer {
    z-index: 100;
    /* position: absolute; */
    width: 100%;
    /* bottom: -200vh; */
    display: flex;
    flex-flow: column;
    background-color:#242424;
}

.footer-title-message {
    font-weight: 800;
    font-size: 2vw;
    margin: 1vw;
    color: white;
}

.footer-message{
    font-weight: 0;
    font-size: .8vw;
    line-height: 2.4vh;
    color:white;
    margin: 1vw;
}

.footer-icon {
    display: flex;
    flex-flow: row;
    gap: 10px;
    justify-content: center;
}

.footer-icon img {
    width: 30px;
}

.footer-contact{
    font-size:0.9rem;
    color:#545e6f;
    margin:0.6rem;
}
.footer-copyright{
    font-size:0.9rem;
    color:white;
    margin:0.6rem;
}`, "",{"version":3,"sources":["webpack://./src/css/footer.css"],"names":[],"mappings":"AAAA,WAAW;AACX;IACI,YAAY;IACZ,wBAAwB;IACxB,WAAW;IACX,oBAAoB;IACpB,aAAa;IACb,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,aAAa;IACb,cAAc;IACd,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;AACjB","sourcesContent":["/* Footer */\nfooter {\n    z-index: 100;\n    /* position: absolute; */\n    width: 100%;\n    /* bottom: -200vh; */\n    display: flex;\n    flex-flow: column;\n    background-color:#242424;\n}\n\n.footer-title-message {\n    font-weight: 800;\n    font-size: 2vw;\n    margin: 1vw;\n    color: white;\n}\n\n.footer-message{\n    font-weight: 0;\n    font-size: .8vw;\n    line-height: 2.4vh;\n    color:white;\n    margin: 1vw;\n}\n\n.footer-icon {\n    display: flex;\n    flex-flow: row;\n    gap: 10px;\n    justify-content: center;\n}\n\n.footer-icon img {\n    width: 30px;\n}\n\n.footer-contact{\n    font-size:0.9rem;\n    color:#545e6f;\n    margin:0.6rem;\n}\n.footer-copyright{\n    font-size:0.9rem;\n    color:white;\n    margin:0.6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
